export default [
  {
    header: 'APP MENU',
  },
  {
    title: 'Home',
    route: 'dashboard',
    icon: 'HomeIcon',
    Permission: '',
  },

  {
    title: 'Formuls',
    icon: 'SlackIcon',
    // Permission: 'formul_list',
    children: [
      {
        title: 'Insert',
        route: 'insert-formul',
        icon: 'PlusSquareIcon',
      },
      {
        title: 'Input',
        route: 'input-list',
        icon: 'ClipboardIcon',
      },
      {
        title: 'Tables',
        route: 'table-list',
        icon: 'SquareIcon',
      },
      // {
      //   title: 'Output',
      //   route: 'calculate-list',
      //   icon: 'EyeIcon',
      // },
    ],
  },
];
